import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
    <Layout>
        <Seo title='About' />
        <div className='content-wrapper'>
            <h1>
                About Synodic
            </h1>

            <p><b>Synodic provides technical & software consultation services, specialising in live sports timing, broadcasting & safety.</b></p>

            <br />
            <br />
            <br />
            <Link
                to='/contact'
                className="link-button"
            >
                GET IN TOUCH
            </Link>
        </div>
    </Layout>
)


export default AboutPage